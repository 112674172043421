import React from "react"
import LandingHome from './landingHome'

export default function Home() {

  return (
    <div id="home">
        <LandingHome />
    </div>
  );
}